export default {
    inserted(el, binding) {
        let {
            value
        } = binding;
        const imageUrl = require('@/assets/images/create-ppt-loading.gif'); // 引入图片
        const wH = window.innerHeight;

        console.log('value', value)
        const loadingEl = document.createElement('div');
            loadingEl.classList.add('J_loading-overlay');
            loadingEl.style.cssText = `
                width: 100%;
                position:absolute;
                top: 38px;
                left: 0;
                right: 0;
                bottom: 0;
                background-color: #ffffff;
                z-index: 2;
                width: 100%;
                height: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
            `;
            loadingEl.style.height = wH - 134 + 'px';

            loadingEl.innerHTML = `
                <img 
                    src="${imageUrl}" 
                    alt="Loading..." 
                    class="loading-image"
                    style='
                        width: 436px;
                    ' 
                />
            `;

        el.appendChild(loadingEl);
        loadingEl.style.display = value ? 'flex' : 'none';
    },
    update(el, binding) {
        const loadingEl = el.getElementsByClassName('J_loading-overlay')[0];
        loadingEl.style.display = binding.value ? 'flex' : 'none';
    },
    unbind(el) {
        const loadingEl = el.getElementsByClassName('J_loading-overlay')[0];
        el.removeChild(loadingEl);
    }
}